@keyframes blink-animation {
    0% {
      color: black;
    }
    50% {
      color: rgb(0, 255, 55);
    }
    100% {
      color: black;
    }
  }

  @media screen and (max-width: 1417px) {
    div {
      font-size: 8px !important;
    }

    .logo {
      height: 15px !important;
    }

  }
@media screen and (min-width: 1417px) {
    div {
      font-size: 10px !important;
    }

    .logo {
      height: 25px !important;
    }

  }

  @media screen and (max-width: 600px) {
    div {
      font-size: 12px !important;
    }

    .logo {
      height: 35px !important;
    }

  }

  @media screen and (min-width: 1642px) {
    div {
      font-size: 12px !important;
    }

    .logo {
      height: 35px !important;
    }

  }

  
  .blink {
    animation: blink-animation 2s infinite;
  }
  
  .logo {
    height: 35px;
  }
  
  .match {
    margin-bottom: 10px;
    display: flex;
  }
  
  .matchTimeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .matchTime {
    text-align: center;
    width: 100px; /* Ajusta el ancho según sea necesario */
    margin-right: 10px; /* Agrega un margen a la derecha */
  }
  
  .matchTeams {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  
  .matchResult {
    margin-left: 10px; /* Agrega un margen a la izquierda */
    align-self: center;
    display: inline block;
  }

  .matchTeams table {
    width: 100%;
  }

  .matchTeams td {
    text-align: center;
  }

  .lightColorGreen{
    background-color: lightgreen;
  }