@keyframes blink-animation {
    0% {
      background-color: white;
    }
    50% {
      background-color: rgb(0, 255, 55);
    }
    100% {
      background-color: white;
    }
  }

  @media screen and (max-width: 699px) {
    div {
      font-size: 12px;
    }
  }
  
  .blink {
    animation: blink-animation 2s infinite;
  }
  
  .logo {
    height: 35px;
  }
  
  .match {
    margin-bottom: 10px;
    display: flex;
  }
  
  .matchTimeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .matchTime {
    text-align: center;
    width: 100px; /* Ajusta el ancho según sea necesario */
    margin-right: 10px; /* Agrega un margen a la derecha */
  }
  
  .matchTeams {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  
  .matchResult {
    margin-left: 10px; /* Agrega un margen a la izquierda */
    align-self: center;
    display: inline block;
  }

  .matchTeams table {
    width: 100%;
  }

  .matchTeams td {
    text-align: center;
  }

  thead > th {border-bottom: 1px solid #333; text-align: center; font-weight: bold;}

  .lightColorGreen{
    background-color: lightgreen;
  }